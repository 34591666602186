.emoji-panel{
    background-color: #fff;
    box-shadow: 0 4px 4px 0 rgba(0,0,0,.06);
    width: 400px;
    z-index: 20;
}
.emoji-panel__heading{
    background-color: #f0f0f0;
    display: flex;
    justify-content: space-between;
}
.emoji-panel__body{
    max-height: 300px;
    overflow: auto;
}
.emoji-panel__btn{
    background: none;
    border: none;
    cursor: pointer;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}
.emoji-active{
    border-bottom: solid 3px #0ac5fe;
}

.emoji-svg {
    transition: 0.2s;
}

.emoji-svg:hover {
    transform: scale(1.5);
}

.px-1px {
    padding-left: 1px !important;
    padding-right: 1px !important; 
}