.textarea-contact {
    min-height: 10rem;
}

.button-contact {
    margin-top: 2rem;
}

@media screen and (max-width: 767px){
    .button-contact {
        width: 100%;
    }
}