#file-upload-container {
    height: 25vh;
    transition: 0.25s;
}

.cards-home {
    width: 36rem;
    max-width: 45%;
    height: 36rem;
    max-height: 75%;
}

.img-home {
    max-height: 50%;
}

.file-previews {
    max-height: 12rem;
    max-width: 12rem;
}

.preview-containers {
    margin-left: 1rem;
    margin-right: 1rem;
    height: 14rem;
    width: 14rem;
    padding: 2rem;
}

.file-select-label {
    top: 0.5rem;
    left: 0.5rem;
}

.image-delete-buttons {
    top: 10px;
    right: 10px;
}

.file-names {
    display: block;
    padding: 0.25rem;
    padding-top: 0.25rem;
    width: 100%;
    background: rgb(74, 74, 74, 0.6);
    color: #fff;
    font-weight: bold;
    white-space: nowrap; 
    overflow: hidden;
}

#image-container {
    scroll-behavior: auto;
}

@media screen and (max-width: 767px){
    #card-container {
        flex-direction: column;
    }

    .cards-home {
        width: 95%;
        max-width: 95%;
        margin: 1rem auto;
        height: max-content;
        max-height: max-content;
    }
}