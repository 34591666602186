#avatar-image {
    height: 10rem;
    width: 10rem;
}

.inputs, .inputs-change-password {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}

.inputs-change-password {
    width: 420px;
    max-width: 95%;
}

@media screen and (max-width: 767px){
    .btn-save {
        width: 100%;
    }
}