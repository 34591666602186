.h-95 {
    height: 95% !important;
}

.buffer {
    height: 2rem;
}

.dashboard-navs {
    cursor: pointer;
    color: black;
}

.select-users {
    width: 12rem;
    max-width: 95%;
}

@media screen and (min-width: 991px){
    .show-sm {
        display: none;
    }

    .show-md {
        display: block;
    }

    .h-lg-100 {
        height: 100%;
    }
}

@media screen and (min-width: 768px){
    .show-sm {
        display: none;
    }

    .show-md {
        display: block;
    }

    .h-md-100 {
        height: 100%;
    }
}

@media screen and (max-width: 767px){
    .show-sm {
        display: block;
    }

    .show-md {
        display: none;
    }

    .h-100 {
        min-height: 100%;
        height: max-content !important;
    }
}