.navbar-user-desktop > button > button, .navbar-user-desktop > button > button:hover, .navbar-user-desktop > button > button:focus {
    box-shadow: none !important;
}

.navbar-user-mobile, #logo-mobile {
    display: none !important;
}

.links-generic {
    text-decoration: none;
    color: white !important;
    transition: 0.25s;
}

.links-generic:hover {
    color: #347aeb !important;
}

.dropdown-item, .nav-link {
    cursor: pointer;
}

#svg-logo {
    height: 4.5rem;
    cursor: pointer;
}

.nav-mobile {
    display: none;
}

#nav-container {
    width: 100%;
    background-image: none;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.nav-items-collapse {
    width: max-content !important;
    margin: 0 auto;
}

.nav-items-collapse > .text-danger {
    color: #fa3f32 !important;
    font-weight: bold;
}

.nav-items-collapse a {
    padding: 0.75rem 1rem 0.75rem 1rem !important;
}

.nav-avatar {
    border-radius: 50%;
    max-height: 2rem;
    max-width: 2rem;
    display: inline-block;
    margin-right: 0.33rem;
}

@media screen and (min-width: 992px){

    .nav-mobile {
        display: none !important;
    }

    .nav-desktop {
        display: block;
    }

    #navbarText {
        height: max-content !important;
    }
    
}

@media screen and (max-width: 991px){
    .navbar-user-mobile, #logo-mobile {
        display: block !important;
    }

    #navbarDropdownMenuLink {
        font-size: 1.25rem;
        padding-left: 0 !important;
    }

    .navbar-user-desktop, #logo-desktop {
        display: none !important;
    }

    #svg-logo {
        height: 4.5rem;
    }

    #nav-container {
        width: max-content;
    }

    #navbar-toggler-main {
        display: block;
    }

    .navbar-toggler-icon {
        height: 2em;
        width: 2em;
    }

    #navbarText {
        margin-top: 0.5rem;
    }

    .ul-main-nav li {
        font-size: 1rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
    }

    .ul-main-nav .nav-item {
        text-align: center;
        cursor: pointer;
    }

    .nav-main-items {
        text-align: end !important;
    }

    .nav-mobile {
        display: block;
    }

    .nav-desktop {
        display: none !important;
    }

    .navbar-nav {
        margin-left: auto !important;
    }
}

