.images {
    cursor: pointer;
    width: 95%;
}

.w-95 {
    width: 95% !important;
}

@media screen and (max-width: 767px){
    .even-rows {
        flex-direction: column-reverse;
    }

    .image-divs {
        border: 0 !important;
    }

    .w-95 {
        width: 95% !important;
    }

    .display-6, .w-95 {
        margin-top: 1rem;
    }

    .image-divs > img {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}