#forgot-password {
    text-align: center;
    text-decoration: none;
    margin-top: 0.9rem;
    width: 100%;
    display: block;
    color: #2196f3 !important;
    cursor: pointer !important;
}

.inputs {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
}